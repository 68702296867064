<template>
    <div class="page-ryProbe-afterSale-info">
        <Nav />
        <div class="header">
            <div class="title">返厂维护记录/维护详情</div>
        </div>
        <div class="content">
            <img v-if="info.status == 3" class="icon-end" :src="ossHost + 'img/order-settlement.png'" alt="">
            <div class="probe-id">{{info.deviceId}}
                <span v-if="info.status == 0" class="success">待确认</span>
                <span v-if="info.status == 1" class="success">待复核</span>
                <span v-if="info.status == 2" class="success">已确认</span>
                <span v-if="info.status == 3" class="success">已结算</span>
                <span v-if="info.status == 4" class="success">已完成</span>
            </div>

            <div class="info-item" v-if="info.status > 2">
                <div class="item-title">最终判定</div>
                <div class="item-content">
                    <div class="item-table item-table-width">
                        <span><i>最终责任方</i> {{ info.responsiblePartyFinal == 5 ? '览宋' : '英沃' }}</span>
                        <span><i>最终处理方式</i> {{info.handlingMethodFinal | filterHandlingMethod}}</span>
                        <span v-if="info.status == 3"><i>结算日期</i> {{info.settlementAt | getYMD}}</span>
                        <span v-if="info.status == 4"><i>完成日期</i> {{info.finishAt | getYMD}}</span>
                    </div>
                </div>
            </div>

            <div class="info-item">
                <div class="item-title">出厂信息</div>
                <div class="item-content">
                    <div class="item-table">
                        <span><i>质检结果</i> {{probeInfo.passed ? '通过' : '未通过'}}</span>
                        <span><i>质检日期</i> {{probeInfo.passTime}}</span>
                    </div>
                    <span class="btn" @click="isShowModal = true">查看质检记录</span>
                </div>
            </div>

            <div class="info-item">
                <div class="item-title">返厂信息</div>
                <div class="item-content border" v-if="info.operatorParty == 5">
                    <div class="return-item text-red">使用记录：</div>
                    <div class="item-table">
                        <span><i>设备编号</i>  {{info.uniqueId}} <span class="i-btn" @click="loadProbeUserHistory">更多 </span></span>
                        <span><i>开始使用日期</i> {{info.startTime}}</span>
                        <span><i>申请维护日期</i> {{info.helpTime}}</span>
                        <span v-if="info.days > 0"><i>累计使用天数</i> {{info.days > 0 ? info.days + '天' : '不足一天'}}</span>
                        <span v-if="info.days == 0"><i>累计使用天数</i>不足一天</span>
                        <span v-if="info.days == undefined"><i>累计使用天数</i> - </span>
                    </div>

                    <div class="item-box">
                        <div class="return-item text-red">返厂原因：<span>{{info.preTagName}}</span></div>
                        <!-- <div class="return-item text-red" v-if="info.status > 1">责任方：
                            <span v-if="info.preResponsibleParty == 5">览宋</span>
                            <span v-if="info.preResponsibleParty == 4">英沃</span>
                        </div> -->
                        <div class="return-item">故障曲线：<span v-if="!info.images.length">暂无</span></div>
                        <div class="img-box">
                            <img :src="url" @click="openNewWindow(url)" v-for="(url, index) in info.images" :key="index" alt="">
                        </div>
                        <div class="return-item">故障描述：</div>
                        <div class="return-remark">{{info.preRemark || '暂无故障描述'}}</div>
                    </div>
                </div>

                <div v-else class="item-content border return-result empty">
                    <span>由英沃手动创建维护订单，暂无返厂信息</span>
                </div>
            </div>

            <div class="info-item" v-if="info.status > 0">
                <div class="item-title">质检记录</div>
                <div class="item-content border">
                    <div class="item-child">责任方： <span style="color: #F52323">{{ info.responsibleParty | filterRespText }}</span></div>
                    <div class="item-child">处理方式： <span style="color: #F52323">{{ info.handlingMethod | filterHandlingMethod }}</span></div>
                    <div class="item-child">
                        <div v-if="info.tagNames && info.tagNames.length">质检结果： {{ info.tagNames.join('、') }}</div>
                        <div v-else>质检结果： 暂无</div>
                    </div>
                    <!-- <div class="item-child" v-else>
                        <div v-if="info.tagNamesFinal && info.tagNamesFinal.length">质检结果： {{ info.tagNamesFinal.join('、') }}</div>
                        <div v-else>质检结果： 暂无</div>
                    </div> -->
                    <div class="item-child">故障描述： {{ info.remark || '暂无' }}</div>

                    <div class="return-item" v-if="info.responsibleParty == 5 && info.status == 1 && isShowSureBtn">
                        <div class="item-confirm-box">
                            <div class="box-title">该订单正在等览宋工程师复核</div>
                            <div class="box-footer">
                                <el-button @click="editCheckInfo(2)" type="danger" size="small">修改判定结果</el-button>
                                <el-button @click="editCheckInfo(1)" type="success" size="small">同意原判定</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-item" v-if="!info.status && !isShowSureBtn">
                <!-- <div class="item-title">质检记录</div> -->
                <div class="item-content">
                    <div class="return-item">
                        <div class="item-confirm-box">
                            <div class="box-title">该订单正在等待英沃工程师填写质检记录</div>
                            <div class="box-footer text-center">
                                <el-button @click="editCheckInfo" type="success" size="small">填写质检记录</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-item" v-if="info.logs && info.logs.length">
                <div class="item-title">协商记录</div>
                <div class="item-content border history-content">
                    <Steps direction="vertical" :active="0">
                        <Step v-for="(item, index) in info.logs" :key="index">
                            <p>{{item.content}}</p>
                            <p>{{item.time}}</p>
                        </Step>
                    </Steps>
                </div>
            </div>
        </div>

        <el-dialog title="出厂质检项"
            :visible.sync="isShowModal"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            center>
            <div class="info-content">
                <div class="info-content-title">出厂质检项：</div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item, index) in failArr" :key="index">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
                <div :class="{'info-content-item': true, 'pass': item.state}" v-for="(item) in passArr" :key="item.data">
                    <Icon v-if="!item.state" name="clear" />
                    <Icon v-else name="checked" />
                    {{item.msg}}
                    <span v-if="item.data">{{item.data}}</span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isShowModal = false">关闭</el-button>
            </div>
        </el-dialog>
        <el-dialog title="关联设备记录"
                   :visible.sync="isShowProbeUseHistory"
                   :close-on-click-modal="true"
                   :close-on-press-escape="true"
                   center>
            <el-table :data="probeUserHistory" style="width: 100%">
              <el-table-column label="设备编号" prop="uniqueId" width="180" />
              <el-table-column label="开始日期" prop="startAt" width="180" >
                <template slot-scope="scope">
                  <div>{{scope.row.startAt | turnTime(false)}}</div>
                </template>
              </el-table-column>
              <el-table-column label="结束日期" prop="endAt">
                <template slot-scope="scope">
                  <div>{{scope.row.endAt | turnTime(false)}}</div>
                </template>
              </el-table-column>
            </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="isShowProbeUseHistory = false">关闭</el-button>
          </div>
        </el-dialog>
        <UpdateProbeCheckModal ref="updateProbeCheckModal" @change="loadInfo" />
    </div>
</template>

<script>
import { Dialog, Toast, Step, Steps, Icon } from "vant";
import { getYMD,turnTime } from '@/utils/time'
import { filterHandlingMethod, filterRespText } from '@/utils/str'
import Nav from '@/components/nav'
import UpdateProbeCheckModal from '@/modal/ryProbe/updateProbeCheckModal';

export default {
    name: "RyProbeAfterSaleInfo",
    components: {
        Nav,
        Step,
        Steps,
        Icon,
        UpdateProbeCheckModal
    },
    filters: {
        turnTime,
        getYMD,
        filterHandlingMethod,
        filterRespText
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            info: {},
            isAdmin: false,
            isShowModal: false,
            isShowProbeUseHistory: false,
            passArr: [],
            failArr: [],
            probeInfo: {},
            probeUserHistory: []
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid')

        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.name = name;
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            (fType != 'yingwo' || !name) && vm.$router.replace({
                path: "/ryProbe/afterSale/login"
            });
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        if (name == 'Yudada') {
            vm.isAdmin = true
        }
        vm.ttid = Toast.loading({
            forbidClick: true,
            overlay: true,
            duration: 0
        });
        vm.loadTag()
    },
    computed: {
        isShowSureBtn() {
            const vm = this;
            const { USER_NAME } = vm.$config.keys;
            const name = vm.$localStorage.getItem(USER_NAME);
            return name == 'Yudada'
        }
    },
    methods: {
        loadInfo() {
            const vm = this
            vm.$http({
                type: 'get',
                url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.$route.params.id}`
            }).then(res => {
               if (res.data) {
                   const { leaveFacQc } = res.data || {}
                   res.data.startTime = getYMD(res.data.startUserAt, true)
                   res.data.helpTime = getYMD(res.data.careInfoAt, true)
                   res.data.checkTime = getYMD(res.data.createAt, true)
                   res.data.sureTime = getYMD(res.data.confirmAt, true)
                   res.data.days = res.data.useDays

                   if (res.data.log && res.data.log.length) {
                       res.data.log.map(log => {
                           log.dayText = `${new Date(log.createAt * 1000).getMonth() + 1}/${new Date(log.createAt * 1000).getDate()}`
                           log.timeText = `${new Date(log.createAt * 1000).getHours()}:${new Date(log.createAt * 1000).getMinutes()}`
                       })
                   }
                   if (leaveFacQc) {
                        leaveFacQc.updateAt && (leaveFacQc.passTime = getYMD(leaveFacQc.updateAt, true))
                        vm.probeInfo = leaveFacQc || [];
                        vm.passArr = leaveFacQc.content.filter(item => item.state);
                        vm.failArr = leaveFacQc.content.filter(item => !item.state);
                   }

                   if (res.data && res.data.logs) {
                        res.data.logs.map(log => {
                            log.dayText = getYMD(log.createAt).split('  ')[0].slice(5, 10)
                            log.hourText = getYMD(log.createAt).split('  ')[1].slice(0, 5)
                            log.time = getYMD(log.createAt)
                        })
                   }

                   vm.info = res.data
                   vm.ttid && vm.ttid.clear();
               }
            }).catch(err => {
                vm.ttid && vm.ttid.clear();
                console.log(err)
            });
        },

        loadTag() {
            const vm = this;
            const params = {
                type: 6
            }
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}returnFac/tags`,
                params
            }).then(res => {
                vm.tagList = res.data
                vm.loadInfo();
            });
        },

        // 填写质检记录
        editCheckInfo(type) {
            this.$refs.updateProbeCheckModal.initData(this.info, type)
        },
        loadProbeUserHistory() {
          const vm = this;
          const params = {
            probeId: this.info.deviceId
          }
          vm.$http({
                     type: "get",
                     url: `${vm.$config.base.PROBE_BASE_URL}probe/history/deviceRelation`,
                     params
                   }).then(res => {
            vm.probeUserHistory = res.data
            vm.isShowProbeUseHistory = true
          });
        },
        openNewWindow(url) {
            window.open(url, '_blank')
        },

        // 点击同意或者拒绝操作 type: 1同意
        onclickActionBtn(type) {
            const vm = this
            let message = '同意后，表明您对该订单无异义，该订单状态将转为“已确认”';
            !type && (message = '拒绝后，该订单将转为“待复核”订单，将由览宋派专人复核')
            Dialog.confirm({
                title: `是否确认${type ? '同意' : '拒绝'}`,
                message,
                confirmButtonColor: type ? "#32BE32" : '#F56C6C',
                confirmButtonText: type ? '同意' : '确认拒绝'
            })
            .then(() => {
                vm.ttid = Toast.loading({
                    forbidClick: true,
                    overlay: true,
                    duration: 0
                });
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/affirm`,
                    data: {
                        confirmStatus: Number(type) || 2,
                        password: vm.$config.base.password,
                        operator: vm.name,
                        careInfoId: vm.info.id
                    }
                }).then(() => {
                    vm.ttid && vm.ttid.clear()
                    Toast.success({
                        message: '操作成功'
                    })
                    vm.loadInfo()
                }).catch(err => {
                    vm.ttid && vm.ttid.clear()
                    vm.loading = false;
                    console.log(err)
                });
            })
            .catch(() => {
                // on cancel
            });
        },

        // 修改责任方
        changeOrder() {
            const vm = this
            Dialog.confirm({
                title: "提示",
                message: `是否将责任方修改为${vm.info.responsibleParty == 4 ? '英沃' : '览宋'}`,
                confirmButtonColor: "#32BE32"
            })
            .then(() => {
                vm.ttid = Toast.loading({
                    forbidClick: true,
                    overlay: true,
                    duration: 0
                });
                vm.$http({
                    type: "POST",
                    url: `${vm.$config.base.PROBE_BASE_URL}careInfo/${vm.info.id}/confirm`,
                    data: {
                        responsibleParty: vm.info.responsibleParty == 1 ? 4 : 1,
                        password: vm.$config.base.password
                    }
                }).then((res) => {
                    vm.ttid && vm.ttid.clear();
                    res.code == 1 ? Toast.success({
                        message: '修改成功'
                    }) : Toast.fail({
                        message: res.message
                    })
                    vm.loadInfo()
                }).catch(err => {
                    vm.ttid && vm.ttid.clear();
                    Toast.success({
                        message: '修改失败~'
                    })
                    console.log(err)
                });
            })
            .catch(() => {
                // on cancel
            });
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-ryProbe-afterSale-info {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    padding: 70px 0 10px;
    font-size: 16px;
    color: @textColor9;
    padding-left: 160px;
    * {
        box-sizing: border-box;
    }
    .header {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        top: 0;
        line-height: 60px;
        padding: 5px 20px;
        padding-left: 180px;
        z-index: 5;
        background: @blank;
        .title {
            color: @textColor10;
            font-size: 20px;
            font-weight: bold;
        }
        .el-form-item__label {
            padding-right: 7px;
        }
    }
    .content {
        padding: 0px 20px 50px;
        height: 100%;
        overflow-y: auto;
        color: #464646;
        position: relative;

        .icon-end {
            position: absolute;
            right: 0;
            top: -20px;
            display: block;
            width: 150px;
        }

        .probe-id {
            font-size: 30px;
            display: flex;
            align-items: center;
            span {
                display: inline-block;
                padding: 5px 10px;
                border-radius: 5px;
                background: #F52323;
                color: #fff;
                font-size: 16px;
                margin-left: 10px;
                &.success {
                    background: #209A56;
                }
            }
        }
        .info-item {
            margin-top: 15px;
            .item-title {
                position: relative;
                font-size: 20px;
                line-height: 40px;
                padding-left: 12px;
                &:after {
                    content: ' ';
                    display: block;
                    width: 6px;
                    height: 20px;
                    top: 10px;
                    left: 0;
                    background: #209A56;
                    position: absolute;
                }
            }

            .item-content {
                margin-top: 10px;
                display: flex;
                font-size: 16px;
                align-items: center;

                .item-child {
                    padding: 5px 0;
                    width: 90%;
                    display: inline-block;
                }

                &.history-content {
                    min-height: 200px;
                    position: relative;

                    .history-item {
                        display: flex;
                        align-items: center;
                        padding-bottom: 30px;
                        .item-time {
                            padding-right: 20px;
                            span {
                                display: block;
                                text-align: right;
                                &.hours {
                                    font-size: 12px;
                                }
                            }
                        }
                        .item-text {
                            color: @themeColor;
                            font-size: 18px;
                            padding-left: 30px;
                            position: relative;
                            &:after {
                                content: ' ';
                                width: 15px;
                                height: 15px;
                                border-radius: 7.5px;
                                background: @themeColor;
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 5px;
                            }
                        }
                    }
                }
                &.border {
                    border: 1px solid #979797;
                    padding: 20px 10px;
                    flex-direction: column;
                    align-items: flex-start;
                }

                &.return-result {
                    min-height: 200px;
                    &.empty {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .return-item {
                        margin-top: 0;
                        margin-bottom: 20px;
                    }
                    .sure-text {
                        color: #209A56;
                        padding: 20px 0;
                        font-size: 30px;
                    }
                    .update-btn {
                        display: inline-block;
                        line-height: 30px;
                        padding: 0 10px;
                        color: #fff;
                        background: #209A56;
                        font-size: 16px;
                        font-weight: normal;
                        border-radius: 5px;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }

                .return-item {
                    margin-top: 20px;
                    font-size: 20px;
                    width: 100%;
                    span {
                        font-weight: bold;
                        margin-right: 30px;
                    }
                    &.text-red {
                        span {
                            color: #F52323;
                        }
                    }

                    .item-confirm-box {
                        width: 400px;
                        text-align: center;
                        background: #F7FBFA;
                        padding: 20px 40px;
                        margin: 0 auto;
                        .box-footer {
                            padding-top: 20px;
                            display: flex;
                            justify-content: space-between;
                            &.text-center {
                                justify-content: center;
                            }
                            span {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .return-remark {
                    padding-top: 10px;
                    font-size: 20px;
                }
                .img-box {
                    padding: 10px 0;
                    img {
                        display: inline-block;
                        width: 32%;
                        max-width: 200px;
                        max-height: 300px;
                        margin-right: 2%;
                        cursor: pointer;
                    }
                }
                .item-table {
                    min-width: 70%;
                    &.item-table-width {
                        width: 100%;
                    }
                    line-height: 34px;
                    border: 1px solid #979797;
                    margin-right: 10px;
                    span {
                        display: inline-block;
                        max-width: 50%;
                        min-width: 150px;
                        padding-right: 20px;
                        i {
                            display: inline-block;
                            font-style: normal;
                            padding: 0 20px;
                            background: fade(#979797, 20);
                            text-align: center;
                            margin-right: 10px;
                        }
                        .i-btn {
                          display: inline-block;
                          color: #2394F5;
                          cursor: pointer;
                          text-align: center;
                        }
                    }
                }
                .btn {
                    color: #2394F5;
                    cursor: pointer;
                }
            }
        }
    }

    .info-content {
        padding: 20px 10px;
        border-radius: 9px;
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
        .info-content-item {
            padding-top: 20px;
            padding-right: 70px;
            position: relative;
            padding-left: 30px;
            i {
                position: absolute;
                left: 0px;
                font-size: 20px;
                color: #256FD9;
                &.van-icon-clear {
                    color: #F52323;
                }
            }

            span {
                position: absolute;
                right: 0;
                color: #FB3D66;
            }

            &.pass {
                color: #858585;
                span {
                    color: #858585;
                }
            }
        }
    }
}
.van-dialog {
    z-index: 99999;
}
</style>


